exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window?.scrollTo(0, 0)
  if (location.hash) {
    return false;
  }

  return true;
};