// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-covid-france-assouplissement-js": () => import("./../../../src/pages/article/covid-france-assouplissement.js" /* webpackChunkName: "component---src-pages-article-covid-france-assouplissement-js" */),
  "component---src-pages-article-covid-vr-travel-js": () => import("./../../../src/pages/article/covid-vr-travel.js" /* webpackChunkName: "component---src-pages-article-covid-vr-travel-js" */),
  "component---src-pages-article-earth-hour-js": () => import("./../../../src/pages/article/earth-hour.js" /* webpackChunkName: "component---src-pages-article-earth-hour-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-cities-js": () => import("./../../../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-stay-thailand-phuket-diamond-js": () => import("./../../../src/pages/stay/thailand/phuket/diamond.js" /* webpackChunkName: "component---src-pages-stay-thailand-phuket-diamond-js" */),
  "component---src-pages-stay-thailand-phuket-platinium-js": () => import("./../../../src/pages/stay/thailand/phuket/platinium.js" /* webpackChunkName: "component---src-pages-stay-thailand-phuket-platinium-js" */),
  "component---src-pages-stay-uae-dubai-gold-js": () => import("./../../../src/pages/stay/uae/dubai/gold.js" /* webpackChunkName: "component---src-pages-stay-uae-dubai-gold-js" */),
  "component---src-pages-stay-uae-dubai-platinium-js": () => import("./../../../src/pages/stay/uae/dubai/platinium.js" /* webpackChunkName: "component---src-pages-stay-uae-dubai-platinium-js" */),
  "component---src-pages-stays-dubai-js": () => import("./../../../src/pages/stays/dubai.js" /* webpackChunkName: "component---src-pages-stays-dubai-js" */),
  "component---src-pages-stays-js": () => import("./../../../src/pages/stays.js" /* webpackChunkName: "component---src-pages-stays-js" */)
}

